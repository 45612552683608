body {
  background-color: #f7f8fc;
}

#codeEditor,
#lineCounter {
  margin: 0;
  padding: 10px;
  height: 60vh;
  resize: none;
  font-size: 16px;
  line-height: 1.2;
  outline: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#lineCounter:focus-visible,
#codeEditor:focus-visible {
  outline: none;
}
#lineCounter {
  display: flex;
  border-color: transparent;

  text-align: right;
  box-shadow: none;
  color: #707070;
  background-color: #d8d8d8;
  position: absolute;
  width: 3.5rem;
  /* Determine appearance of line counter */
  background-color: #3e3d32;
  border-color: #3e3d32;
  color: #928869;
}
#codeEditor {
  padding-left: calc(3.5rem + 5px);
  width: 100%;
  /* Determine appearance of code editor */
  background-color: #272822;
  border-color: #272822;
  color: #ffffff;
}

#codeEditor::-webkit-scrollbar-track {
  background-color: transparent;
  padding: 10px 0;
}

#codeEditor::-webkit-scrollbar {
  width: 10px;
  padding: 10px 0;
  border-radius: 10px;
  z-index: -10;
  background-color: #272822;
}

#codeEditor::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background-color: #555;
}

.f-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  z-index: 4;
}

.creator-modal {
  position: absolute;
  width: 50%;
  height: 80%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  outline: none;
  background-color: #ffffff;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  border-color: none;
  padding: 2rem;
  color: white;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.pagination button {
  padding: 5px 10px;
  background-color: #808080;
  border-radius: 5px;
  margin: 0 5px;
}
.side-navbar-menu-container::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .creator-modal {
    width: 100%;
    height: 100%;
  }
}
