@tailwind base;
@tailwind components;
@tailwind utilities;

.nft-modal::-webkit-scrollbar {
  display: block;
  width: 5px !important;
  height: 5px !important;
}

.nft-modal::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Tweaking Chrome Scrollbar on Large Screen to Hide */
#nft-scroll-container::-webkit-scrollbar {
  display: none;
}